import React from "react"
import { Flex } from "rebass"
import { MENU_HEIGHT } from "./Header/Header.styles"

export const Wrapper = ({ isDark, ...props }) => (
  <Flex
    flexDirection="column"
    minHeight="100vh"
    sx={{
      position: "relative",
      backgroundColor: isDark ? "#101010" : "#101010",
    }}
    {...props}
  />
)
export const ContentWrapper = ({ sx, ...props }) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    as="main"
    marginTop={MENU_HEIGHT}
    sx={{
      overflowX: "hidden",
      ...sx,
    }}
    {...props}
  />
)
