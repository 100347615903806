import { WidthLimitation } from "@components/Content"
import Logo from "@components/Logo"
import Menu from "@components/Menu"
import MenuMobile from "@components/MenuMobile"
import Translate from "@components/Translate"
import { useOnClickOutside } from "@utils/hooks/useOnClickOutside"
import { useToggle } from "@utils/hooks/useToggle"
import routes from "@utils/routes"
import { Botao } from "@xapps/design-system"
import { Link } from "gatsby"
import React, { Fragment, useRef } from "react"
import * as Style from "./Header.styles"
import { IdsDosBotoesDeConversaoDoLeadsCenter } from "@constants/forms"

function Header({
  underMenuItem,
  hideHeaderButtons,
  hideMenu: hideMenuSection,
}) {
  const [showMobileMenu, toggleMobileMenu, hideMenu] = useToggle()
  const headerRef = useRef()

  useOnClickOutside(headerRef, hideMenu)

  return (
    <Style.Wrapper ref={headerRef}>
      <WidthLimitation
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Link to={routes.home}>
          <Logo height="auto" width="10em" />
        </Link>

        <Fragment>
          {!hideMenuSection && <Menu underMenuItem={underMenuItem} />}
          {!hideHeaderButtons && (
            <Style.ButtonWrapper>
              {process.env.GATSBY_LANGUAGE !== "en" && (
                <Botao
                  mr={3}
                  py={3}
                  variant={
                    process.env.GATSBY_LANGUAGE === "pt-BR"
                      ? "terciario"
                      : "primario"
                  }
                  as={Link}
                  // href="https://perfil.x-apps.com.br/"
                  href={routes.contrateSquads}
                >
                  <Translate id="header.buttons.hireSquad" />
                </Botao>
              )}
              <Botao
                py={3}
                as={Link}
                to={`${routes.contato}?form_id=${IdsDosBotoesDeConversaoDoLeadsCenter.header}`}
                variant={
                  process.env.GATSBY_LANGUAGE === "pt-BR"
                    ? "primario"
                    : "terciario"
                }
              >
                <Translate id="header.buttons.requestBudget" />
              </Botao>
            </Style.ButtonWrapper>
          )}
          {!hideMenuSection && (
            <MenuMobile show={showMobileMenu} toggle={toggleMobileMenu} />
          )}
        </Fragment>
      </WidthLimitation>
    </Style.Wrapper>
  )
}

export default Header
